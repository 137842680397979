<template lang="html">
  <div class="page">
    <h1>
      <w-icon>mdi mdi-sprout</w-icon>
      美味しく&nbsp;育つ&nbsp;<ruby>長狭<rt>ながさ</rt></ruby>の&nbsp;ひみつ
    </h1>
    <div class="image-animation">
      <div ref="container" class="container">
        <img src="/image/page/service/nagasa-mai/secret/top/overall_1.jpg" alt="長狭米が美味しく育つひみつを示す図１">
        <img src="/image/page/service/nagasa-mai/secret/top/overall_2.jpg" alt="長狭米が美味しく育つひみつを示す図２">
        <img src="/image/page/service/nagasa-mai/secret/top/overall_3.jpg" alt="長狭米が美味しく育つひみつを示す図３">
        <img src="/image/page/service/nagasa-mai/secret/top/overall_4.jpg" alt="長狭米が美味しく育つひみつを示す図４">
      </div>
    </div>
    <div class="content">
      <p>
        &emsp;
        <router-link to="/service/nagasa-mai/detail/#map">
          上空から長狭の平野
        </router-link>
        をご覧いただくと、特徴的な地形であることに驚かれることでしょう。
        古来より、この土地で育つ稲が美味しいお米を実らせる、その理由は、この地形に潜んだ３つの<b>ひみつ</b>にあるわけなのです。
      </p>
      <h2>
        <w-icon color="success">mdi mdi-check-bold</w-icon>
        １．<w-icon>mdi mdi-shower-head</w-icon>
         天然水が&nbsp;稲に&nbsp;注ぎ込まれる
      </h2>
      <w-divider/>
      <w-image tag="img" width="100%" style="max-width: 500px" class="bdrs5" src="/image/page/service/nagasa-mai/secret/point/mount.jpg"></w-image>
      <p>
        &emsp;豊かな森林に保水された天然水には、豊富な<b>ミネラル</b>が含まれます。
        北と南に並んでそびえ立った山々がつくりだす天然水は、つねに長狭平野に向って注ぎ込まれ、
        東西に細長い特徴を持つ平野の土地を、余すこと無く満たすようにして広がるため、
        長狭平野の稲は、天然水に含まれる有り余るほどの豊富な<b>ミネラル</b>を多分に吸収しながら育っていきます。
        <br>&emsp;ここで、<u>重要なのは、平野が細長いということ</u>です。
        平野が広大な盆地のような地形では、山々からの<b>ミネラル</b>が平野の全体には行き届きません。
        長狭平野の実際の地形をご覧いただきますと、この土地がまさに稲を育てるのに
        うってつけだということがお解りいただけることとと思います。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/detail/#map">
          実際の地形
          <w-icon>mdi mdi-map-search-outline</w-icon>
        </router-link>
      </p>
      <h2>
        <w-icon color="success">mdi mdi-check-bold</w-icon>
        ２．<w-icon>mdi mdi-weather-sunny</w-icon>
        太陽の光が&nbsp;遮られない
      </h2>
      <w-divider/>
      <w-image tag="img" width="100%" style="max-width: 500px" class="bdrs5 mt8" src="/image/page/service/nagasa-mai/secret/point/sun.jpg"></w-image>
      <p>
        &emsp;太陽の光は、天気の差はあれど、日本全国どの地域にも公平に配られる自然からの大きな贈り物です。
        しかし 実は、その土地の日照時間は、地形によって大きく左右されます。
        東や西に山がある土地では日の出と日の入りが遅くなる分、日照時間が少なくなるのです。
        東西に細長い特徴を持つ長狭平野では、日の出直後から日の入り直前まで、太陽の光を余すこと無く存分に浴びることができるため、
        長狭平野で育つ稲は、長時間の光合成によって高品質な<b>デンプン</b>が大量に作られるのです。
        <br>&emsp;ここで、<u>重要なのは、平野が東西に細長いということ</u>です。
        長狭平野の実際の地形をご覧いただきますと、この土地がまさに稲を育てるのに
        うってつけだということがお解りいただけることとと思います。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/detail/#map">
          実際の地形
          <w-icon>mdi mdi-map-search-outline</w-icon>
        </router-link>
      </p>
      <h2>
        <w-icon color="success">mdi mdi-check-bold</w-icon>
        ３．<w-icon>mdi mdi-weather-windy</w-icon>
        偏西風がつくる&nbsp;寒暖差
      </h2>
      <w-divider/>
      <w-image tag="img" width="100%" style="max-width: 500px" class="bdrs5" src="/image/page/service/nagasa-mai/secret/point/wind.jpg"></w-image>
      <p>
        &emsp;偏西風は一年を通して西から東に向かって流れる気流です。
        東西に細長い特徴を持つ長狭平野では、この偏西風によって
        昼間の温まった空気が海に押し流され、夜に寒さをもたらします。
        そのため、長狭平野で育つ稲は、夜になると寒さによって代謝が抑えられる分、
        使われなかった<b>デンプン</b>が米粒に多く蓄えられ、
        また次の日には光合成によって更に多くの<b>デンプン</b>が蓄えられる、
        といった好循環がもたらされています。
        <br>&emsp;ここで、<u>重要なのは、平野の東側が海に面しているということ</u>です。
        長狭平野の実際の地形をご覧いただきますと、この土地がまさに稲を育てるのに
        うってつけだということがお解りいただけることとと思います。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/detail/#map">
          実際の地形
          <w-icon>mdi mdi-map-search-outline</w-icon>
        </router-link>
      </p>
      <h2>
        <w-icon color="success">mdi mdi-check-bold</w-icon>
        この平野の どまんなかで...
      </h2>
      <w-divider/>
      <w-image tag="img" width="100%" style="max-width: 500px" class="bdrs5 mt8" src="/image/page/service/nagasa-mai/secret/center/center.jpg"></w-image>
      <p>
        &emsp;このような恵まれた好条件のもとで育った稲から採れるお米の美味しさは、
        それはもう言葉では例えようのないほどの美味しさです。
        我が家の家系は古来より、この米どころ長狭平野の文字通りどまんなかで、
        美味しいお米を、ただただ ひたむきにつくり続けてきました。
        <br>&emsp;自慢の味をそのままのかたちで、安くお客様にお渡しするために、
        房総半島の鴨川市へ遊びに来られたお客様に、お米のお持ち帰りサービスを始めました。
      </p>
      <p class="center">
        <w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/takeout/">
          お持ち帰りサービス
          <w-icon>mdi mdi-car-hatchback</w-icon>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animation: {
        timer: null,
        index: 0
      }
    }
  },
  mounted() {
    this.animation.timer = setInterval(() => {
      this.imageAnimationStep()
    }, 2000)
    this.imageAnimationStep()
  },
  beforeUnmount() {
    clearInterval(this.animation.timer)
  },
  methods: {
    imageAnimationStep() {
      const elms = this.$refs.container.children
      const index = (this.animation.index++) % elms.length
      for (let i = 0; i < elms.length; i ++) {
        const elm = elms[i]
        elm.classList[i === index ? 'add' : 'remove']('image-animation-select')
        elm.classList[i === index ? 'remove' : 'add']('image-animation-unselect')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
}
.image-animation {
  .container {
    position: relative;
    margin-top: 30px;
    width: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      opacity: 0;
      &:first-child {
        position: relative;
      }
      &.image-animation-select {
        z-index: 1;
        transition: opacity 1s;
        opacity: 1;
      }
      &.image-animation-unselect {
        z-index: auto;
        transition: opacity 1s 1s;
        opacity: 0;
      }
    }
  }
}
</style>
